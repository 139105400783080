<template>
    <div class="amlm" @click="hideActive2">
        <el-container>
            <el-header
                style="height: 48px"
                :style="{
                    background: 'url(' + require('@/assets/nav_bg.png') + ')',
                }"
            >
                <div class="img_box">
                    <img src="@/img/logo.png" alt="" />
                </div>
                <div class="nav_box">
                    <span
                        v-if="index < 9"
                        :class="active == index ? 'active' : ''"
                        v-for="(item, index) in menuList"
                        :key="item.id"
                        @click.stop="nav(item, index, 1)"
                    >
                        {{ item.name }}
                    </span>

                    <div class="more_nav" v-if="menuList.length > 9">
                        <span
                            @click.stop="moreNav"
                            :class="active2 == -1 ? 'active' : ''"
                        >
                            更多 <i class="iconfont icon-zhankai1"></i>
                        </span>
                        <div
                            class="more_nav_list"
                            @click.stop="showActive2"
                            v-if="active2 == -1"
                        >
                            <p
                                v-for="(item, index) in menuList"
                                :key="item.id"
                                v-if="index > 8"
                                :class="active == index ? 'active' : ''"
                                @click.stop="nav(item, index)"
                            >
                                {{ item.name }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="quanju_nav" @click.stop="showTag">
                    <i class="iconfont icon-zhifeiji"></i>
                    全局导航
                </div>
            </el-header>
            <el-container style="overflow: hidden">
                <el-collapse-transition>
                    <el-aside width="auto">
                        <div class="nav-top">
                            <p v-if="!isCollapse">{{ oneNav.name }}</p>
                            <span class="fold" v-if="!isCollapse">
                                <i
                                    @click.stop="onHide()"
                                    class="el-icon-s-fold"
                                    v-if="!isCollapse"
                                ></i>
                                <i
                                    @click.stop="onAppear()"
                                    class="el-icon-s-unfold"
                                    v-if="isCollapse"
                                ></i>
                            </span>
                            <div v-else>
                                <i :class="oneNav.icon"></i>
                                <span class="fold">
                                    <i
                                        @click.stop="onHide()"
                                        class="el-icon-s-fold"
                                        v-if="!isCollapse"
                                    ></i>
                                    <i
                                        @click.stop="onAppear()"
                                        class="el-icon-s-unfold"
                                        v-if="isCollapse"
                                    ></i>
                                </span>
                            </div>
                        </div>
                        <el-menu
                            class="el-menu-vertical-demo"
                            router
                            @select="select"
                            :collapse="isCollapse"
                            :default-active="$route.path"
                        >
                            <div v-for="item in subNav" :key="item.id">
                                <el-menu-item
                                    v-if="!item.children"
                                    :index="'/' + item.route"
                                >
                                    <i
                                        style="
                                            margin: 0 6px 0 0;
                                            width: 16px;
                                            display: inline-block;
                                        "
                                    >
                                        {{ item.icon }}
                                    </i>

                                    <span slot="title">{{ item.name }}</span>
                                </el-menu-item>
                                <el-submenu v-else :index="'/' + item.route">
                                    <span slot="title"
                                        ><i
                                            style="
                                                margin: 0 6px 0 0;
                                                width: 16px;
                                                display: inline-block;
                                            "
                                        >
                                            {{ item.icon }} </i
                                        >{{ isCollapse ? '' : item.name }}</span
                                    >
                                    <el-menu-item
                                        v-for="itm in item.children"
                                        :key="itm.id"
                                        :index="'/' + itm.route"
                                    >
                                        <i
                                            style="
                                                margin: 0 6px 0 0;
                                                width: 16px;
                                                display: inline-block;
                                            "
                                        >
                                            {{ itm.icon }}
                                        </i>
                                        <span slot="title">
                                            {{ itm.name }}</span
                                        >
                                    </el-menu-item>
                                </el-submenu>
                            </div>
                        </el-menu>
                        <!-- <div :class="isCollapse ? 'side2' : 'side'" ref="side">
                            <p v-for="item in subNav" :key="item.id">
                                <span
                                    @click.stop="toSubNav(item.route)"
                                    v-if="!item.children"
                                    :class="
                                        activeIndex == '/' + item.route
                                            ? 'active'
                                            : ''
                                    "
                                >
                                    <i>
                                        {{ item.icon }}
                                    </i>
                                    {{ isCollapse ? '' : item.name }}
                                </span>
                                <span v-else>
                                    <el-menu router>
                                        <el-submenu :index="'/' + item.route">
                                            <span slot="title"
                                                ><i> {{ item.icon }} </i
                                                >{{
                                                    isCollapse ? '' : item.name
                                                }}</span
                                            >
                                            <el-menu-item
                                                v-for="itm in item.children"
                                                :key="itm.id"
                                                :index="'/' + itm.route"
                                                >{{ itm.name }}</el-menu-item
                                            >
                                        </el-submenu>
                                    </el-menu>
                                </span>
                            </p>
                        </div> -->
                        <div class="avatar_box">
                            <el-avatar>
                                <img src="@/assets/avatar.png" alt="" />
                            </el-avatar>
                            <img
                                class="icon-dingdan1"
                                src="@/assets/11.png"
                                alt=""
                                @click="
                                    () => {
                                        this.$router.push('/updatelog');
                                    }
                                "
                            />

                            <div v-show="!isCollapse" style="flex: 1">
                                <el-dropdown
                                    trigger="click"
                                    style="width: 100%"
                                    @command="handleCommand"
                                >
                                    <div
                                        style="
                                            display: flex;
                                            width: 100%;
                                            cursor: pointer;
                                        "
                                    >
                                        <p
                                            v-show="!isCollapse"
                                            style="flex: 1; max-width: 74px"
                                        >
                                            {{ userName }}
                                            <!-- <span>腾讯AM</span> -->
                                        </p>
                                        <span class="el-dropdown-link">
                                            <i
                                                class="iconfont icon-shezhi"
                                                style="margin-right: 10px"
                                            ></i>
                                        </span>
                                    </div>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="a"
                                            >退出登录</el-dropdown-item
                                        >
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                    </el-aside>
                </el-collapse-transition>

                <el-main>
                    <div v-show="tag" class="cover" @click.prevent="hideTag">
                        <div
                            class="nav_posi"
                            v-show="tag"
                            @click.stop="showTag"
                        >
                            <div
                                v-for="(item, index) in menuList"
                                :key="item.id"
                            >
                                <p
                                    style="font-size: 14px; cursor: pointer"
                                    @click.stop="nav(item, index)"
                                >
                                    <i :class="item.icon"></i>
                                    {{ item.name }}
                                </p>
                                <div
                                    v-if="
                                        item.children &&
                                        item.children.length > 0
                                    "
                                >
                                    <p
                                        class="sub"
                                        v-for="itm in item.children"
                                        @click.stop="toSubNav2(itm)"
                                        :key="itm.id"
                                    >
                                        {{ itm.name }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <router-view v-if="isRouterAlive"> </router-view>
                </el-main>
            </el-container>
            <!-- <div
        class="cover"
        v-if="active2 == -1"
        
      ></div> -->
        </el-container>
    </div>
</template>
<script>
import { menuList } from '@/api/menu';
export default {
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            sidebarWidth: '200px',
            isCollapse: false,
            menuList: [],
            menuData: [],
            isColor: 0,

            breadcrumb: {},
            secede: false,
            userName: sessionStorage.getItem('nickName'),
            activeIndex: this.$route.path,
            isData: false,
            isWork: false,
            menuListArr: [],
            subNav: [],
            active: 0,
            active2: 0,
            subRoute: '',
            tag: false,
            oneNav: JSON.parse(sessionStorage.getItem('nav')),
            isRouterAlive: true,
        };
    },
    mounted() {
        sessionStorage.removeItem('customerlist');
        if (sessionStorage.getItem('nav')) {
            this.nav(
                JSON.parse(sessionStorage.getItem('nav')),
                sessionStorage.getItem('active') || 0,
                2
            );
            sessionStorage.removeItem('searchCompanyName');
        }
        this.getMenu();
        // for (let i = 0; i < 1024 * 1024; i++) {
        //   var total = unescape(encodeURIComponent(JSON.stringify(sessionStorage)))
        //     .length;
        //   // console.log(total / 1024 / 1024 + "M");
        // }
    },
    methods: {
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
        getMenu() {
            let data = {
                param: {},
            };
            menuList(data).then((res) => {
                this.menuData = res.data;
                this.menuList = this.$Tree.treeDataTranslate(
                    res.data,
                    'id',
                    'parentId'
                );
                this.isData = false;

                res.data.forEach((item) => {
                    if (item.route == 'searchUserCustomer') {
                        sessionStorage.setItem('searchUserCustomer', true);
                    }
                    if (item.route == 'demp') {
                        sessionStorage.setItem('demp', true);
                    }
                    if (item.route == 'customerHistory') {
                        sessionStorage.setItem('customerHistory', true);
                    }
                    if (item.route == 'depSearchCustomer') {
                        sessionStorage.setItem('depSearchCustomer', true);
                    }
                    if (item.route == 'reportInfoUpdate') {
                        sessionStorage.setItem('reportInfoUpdate', true);
                    }
                    if (item.route == 'trialOrderUpdate') {
                        sessionStorage.setItem('trialOrderUpdate', true);
                    }
                    if (item.route == 'businessInfoUpdate') {
                        sessionStorage.setItem('businessInfoUpdate', true);
                    }
                });

                for (let i in this.menuList) {
                    if (this.menuList[i].route == 'datacenter') {
                        this.menuList.splice(i, 1);
                        this.isData = true;
                    }
                }
                for (let i in this.menuList) {
                    if (this.menuList[i].route == 'workbench') {
                        this.isWork = true;
                    }
                }
                for (let i in this.menuData) {
                    if ('/' + this.menuData[i].route == this.$route.path) {
                        if (this.menuData[i]._level) {
                            for (let j in this.menuList) {
                                if (
                                    this.menuData[i].parentId ==
                                    this.menuList[j].id
                                ) {
                                    this.breadcrumb = this.menuList[j];
                                    break;
                                }
                            }
                        } else if (
                            this.$route.path.indexOf('detail') != -1 ||
                            this.$route.path.indexOf('add') != -1 ||
                            this.$route.path.indexOf('Detail') != -1
                        ) {
                            // console.log('111');
                            break;
                        } else {
                            this.breadcrumb = this.menuData[i];
                        }
                    }
                }
            });
        },

        onHide() {
            this.isCollapse = true;
        },
        onAppear() {
            this.isCollapse = false;
        },

        onSecede() {
            if (this.secede) {
                window.history.go(-1);
                this.getMenu();
                if (this.$route.path == '/datacenter') {
                    this.isColor = 2;
                }
            }
        },
        onDataCenter(i) {
            this.isColor = i;
            if (i == 1) {
                this.$router.push('/workbench');
            } else {
                this.$router.push('/datacenter');
            }
        },
        // 更新日志
        onJournal() {
            this.$router.push('/updatelog');
            this.breadcrumb = {
                name: '更新日志',
                children: [],
            };
        },

        handleCommand(command) {
            // console.log(command);
            if (command == 'a') {
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push('/');
            } else if (command == 'b') {
                this.$router.push('/personal');
            }
        },
        nav(item, index, num) {
            this.active = index;
            this.oneNav = item;
            this.active2 = 0;
            this.$formDetails.commit('emptyData');
            sessionStorage.setItem('active', index);
            sessionStorage.setItem('nav', JSON.stringify(item));
            if (item.children && item.children.length > 0) {
                this.isCollapse = false;
                this.subNav = item.children;
                var idx = 1;
                item.children.some((itm) => {
                    if (itm.children && itm.children.length > 0) {
                        return itm.children.some((i) => {
                            if ('/' + i.route == this.$route.path) {
                                idx = 1;
                                return true;
                            } else {
                                idx = -1;
                            }
                        });
                    } else {
                        if ('/' + itm.route == this.$route.path) {
                            idx = 1;
                            return true;
                        } else {
                            idx = -1;
                        }
                    }
                });

                if (idx == -1) {
                    if (
                        num == 2 &&
                        (this.$route.path.indexOf('details') != -1 ||
                            this.$route.path.indexOf('add') != -1 ||
                            this.$route.path.indexOf('Detail') != -1)
                    ) {
                        return;
                    }
                    sessionStorage.removeItem('pageData');

                    if (item.children[0].children) {
                        this.$router.push(
                            '/' + item.children[0].children[0].route
                        );
                    } else {
                        this.$router.push('/' + item.children[0].route);
                    }
                } else {
                    sessionStorage.removeItem('pageData');
                    this.$router.push(this.$route.path);
                }
            } else {
                if (this.$route.path == '/contractdetails' && num == 2) {
                    this.isCollapse = true;
                    return;
                }
                this.subNav = [];
                this.isCollapse = true;
                if ('/' + item.route != this.$route.path) {
                    this.$router.push('/' + item.route);
                } else {
                    this.$router.push(this.$route.path);
                }
            }
        },
        toSubNav(url) {
            sessionStorage.removeItem('pageData');
            this.$router.push('/' + url);
        },
        toSubNav3(url) {
            sessionStorage.removeItem('pageData');
            this.$router.push('/' + url);
        },
        toSubNav2(item) {
            sessionStorage.removeItem('pageData');
            for (let i in this.menuList) {
                if (this.menuList[i].id == item.parentId) {
                    this.nav(this.menuList[i], i);
                    if (item.children) {
                        this.toSubNav(item.children[0].route);
                    } else {
                        this.toSubNav(item.route);
                    }
                }
            }
        },
        showTag() {
            this.tag = true;
        },
        hideTag() {
            this.tag = false;
        },
        showActive2() {
            this.active2 = -1;
        },
        hideActive2() {
            this.active2 = 0;
        },
        moreNav() {
            this.active2 = -1;
        },
        select(index) {
            sessionStorage.removeItem('pageData');
        },
    },

    watch: {
        $route: {
            handler(val, oldval) {
                if (this.oneNav.children && this.oneNav.children.length > 0) {
                    if (
                        !this.oneNav.children.some((item) => {
                            if (item.children && item.children.length > 0) {
                                return item.children.some((itm) => {
                                    return '/' + itm.route == val.path;
                                });
                            } else {
                                return '/' + item.route == val.path;
                            }
                        })
                    ) {
                        this.menuList.forEach((item, index) => {
                            if (item.children && item.children.length > 0) {
                                item.children.forEach((itm) => {
                                    if (
                                        itm.children &&
                                        itm.children.length > 0
                                    ) {
                                        itm.children.forEach((i) => {
                                            if (
                                                i.children &&
                                                i.children.length > 0
                                            ) {
                                                i.children.forEach((em) => {
                                                    if (
                                                        '/' + em.route ==
                                                        val.path
                                                    ) {
                                                        this.nav(
                                                            item,
                                                            index,
                                                            1
                                                        );
                                                    }
                                                });
                                            } else {
                                                if ('/' + i.route == val.path) {
                                                    this.nav(item, index, 1);
                                                }
                                            }
                                        });
                                    } else {
                                        if ('/' + itm.route == val.path) {
                                            this.nav(item, index, 1);
                                        }
                                    }
                                });
                            } else {
                                if ('/' + item.route == val.path) {
                                    this.nav(item, index, 1);
                                }
                            }
                        });
                    }
                }
                this.activeIndex = val.path;
                if (
                    val.path.indexOf('detail') != -1 ||
                    val.path.indexOf('add') != -1
                ) {
                    // if (
                    //     val.path == '/condetails' ||
                    //     val.path == '/addApplyinvoice' ||
                    //     val.path == '/addReturnPlan' ||
                    //     val.path == '/dataBasedetails' ||
                    //     val.path == '/lmCluedetails' ||
                    //     val.path == '/cluedetails' ||
                    //     val.path == '/depCluedetails' ||
                    //     val.path == '/addDataBaseCus' ||
                    //     val.path == '/addAliclue' ||
                    //     val.path == '/aliCluedetails' ||
                    //     val.path == '/customerdetails' ||
                    //     val.path == '/businessCountdetails' ||
                    //     val.path == '/aliReportingdetails' ||
                    //     val.path == '/contractdetails' ||
                    //     val.path == '/editRoleaddConfig' ||
                    //     val.path == '/wyReportingdetails' ||
                    //     val.path == '/addorder' ||
                    //     val.path == '/declareinfodetails'
                    // ) {
                    return;
                    // }
                    for (let i in this.menuListArr[this.isColor].menuList) {
                        if (
                            '/' +
                                this.menuListArr[this.isColor].menuList[i]
                                    .route ==
                            oldval.path
                        ) {
                            if (val.path.indexOf('add') != -1) {
                                this.breadcrumb = {
                                    name: this.menuListArr[this.isColor]
                                        .menuList[i].name,
                                    children: [
                                        {
                                            name: '新增',
                                        },
                                    ],
                                };
                            } else if (val.path.indexOf('detail') != -1) {
                                this.breadcrumb = {
                                    name: this.menuListArr[this.isColor]
                                        .menuList[i].name,
                                    children: [
                                        {
                                            name: '详情',
                                        },
                                    ],
                                };
                            }
                            sessionStorage.setItem(
                                'levelPage',
                                JSON.stringify(this.breadcrumb)
                            );
                        }
                    }
                    this.secede = true;
                } else if (val.path.indexOf('confirmorder') != -1) {
                    this.breadcrumb = {
                        name: '已到款合同',
                        children: [
                            {
                                name: '确认合同订单',
                            },
                        ],
                    };
                    this.secede = true;
                } else if (val.path.indexOf('aearchiving') != -1) {
                    if (this.$route.query.type) {
                        this.breadcrumb = {
                            name: '业绩申报',
                            children: [
                                {
                                    name: '编辑申报',
                                },
                            ],
                        };

                        this.secede = true;
                    } else {
                        this.breadcrumb = {
                            name: '合同列表',
                            children: [
                                {
                                    name: '业绩申报及归档',
                                },
                            ],
                        };
                    }

                    this.secede = true;
                } else if (val.path.indexOf('integration') != -1) {
                    this.breadcrumb = {
                        name: '资料库',
                        children: [
                            {
                                name: '整合信息',
                            },
                        ],
                    };
                    this.secede = true;
                } else {
                    this.secede = false;
                }
                let item = '';
                if (this.menuList.length > 0) {
                    for (let i in this.menuList) {
                        if (val.path == '/' + this.menuList[i].route) {
                            if (
                                this.menuList[i].children &&
                                this.menuList[i].children.length > 0
                            ) {
                                for (let j in this.menuList[i].children) {
                                    if (
                                        val.path ==
                                        '/' + this.menuList[i].children[j].route
                                    ) {
                                        item = this.menuList[i].children[j];
                                        break;
                                    }
                                }
                            } else {
                                item = this.menuList[i];
                                break;
                            }
                        } else {
                            if (
                                this.menuList[i].children &&
                                this.menuList[i].children.length > 0
                            ) {
                                for (let j in this.menuList[i].children) {
                                    if (
                                        val.path ==
                                        '/' + this.menuList[i].children[j].route
                                    ) {
                                        item = this.menuList[i].children[j];
                                        break;
                                    } else {
                                        if (
                                            this.menuList[i].children[j]
                                                .children &&
                                            this.menuList[i].children[j]
                                                .children.length > 0
                                        ) {
                                            for (let k in this.menuList[i]
                                                .children[j].children) {
                                                if (
                                                    val.path ==
                                                    '/' +
                                                        this.menuList[i]
                                                            .children[j]
                                                            .children[k].route
                                                ) {
                                                    item =
                                                        this.menuList[i]
                                                            .children[j]
                                                            .children[k];
                                                    console.log(item);
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    let children = this.$Tree.childrenData(this.menuData);

                    let menuArr = this.$Tree.treeDataTranslateAudit(
                        children,
                        'id',
                        'parentId'
                    );
                    this.subRoute = item.route;

                    let isButArr = [];

                    if (item.children && item.children.length > 0) {
                        for (let j = 0; j < item.children.length; i++) {
                            if ('/' + item.children[j].route == val.path) {
                                for (let i in menuArr) {
                                    if (menuArr[i].type == 2) {
                                        if (
                                            menuArr[i].parentId ==
                                            item.children[j].id
                                        ) {
                                            isButArr.push(menuArr[i]);
                                        }
                                    }
                                }
                            }
                            return sessionStorage.setItem(
                                'isButArr',
                                JSON.stringify(isButArr)
                            );
                        }
                    }

                    for (let i in menuArr) {
                        if (menuArr[i].type == 2) {
                            if (menuArr[i].parentId == item.id) {
                                isButArr.push(menuArr[i]);
                            }
                        }
                    }

                    sessionStorage.setItem(
                        'isButArr',
                        JSON.stringify(isButArr)
                    );
                }
            },
            // 深度观察监听
            deep: true,
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
// .aside {
//     transition: all 0.3s;
// }
.nav-top {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    > p {
        flex: 1;
        padding-left: 20px;
        font-size: 13px;
        font-weight: bold;
    }
    .fold {
        color: #338aeb;
        margin-right: 10px;

        i {
            font-size: 18px;
            cursor: pointer;
        }
    }
    > div {
        margin: 0 6px;
        background: #397dff;
        border-radius: 8px;
        width: 52px;
        height: 24px;
        position: relative;
        line-height: 24px;
        color: #fff !important;
        > i {
            position: absolute;
            left: 0;
            top: 0;
            display: inline-block;
            width: 24px;
            height: 24px;
            background: #5891ff;
            border-radius: 8px;
            vertical-align: top;
            text-align: center;
            line-height: 24px;
        }
        .icon-youxiang {
            transform: translateY(0);
        }

        .fold {
            margin-right: 0;
            color: #fff;
            margin-left: 28px;
        }
    }
}
.more_nav {
    position: relative;
    color: #333;
    > span {
        color: #fff;
    }
    .more_nav_list {
        z-index: 999;
        position: absolute;
        top: 48px;
        left: 0;
        padding: 13px 15px;
        background: #ffffff;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);
        p {
            white-space: nowrap;
            font-size: 14px;
            line-height: 1;
            user-select: none;
            cursor: pointer;
        }
        p + p {
            margin-top: 18px;
        }
        .active {
            color: #579fef;
            border-radius: 0px !important;
        }
    }
}
.icon-youxiang {
    position: relative;
    top: 28px;
    left: 0px;
}
#youxiang {
    top: 0;
}
.cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 199;
    .nav_posi {
        position: absolute;
        top: 12px;
        right: 20px;
        width: calc(100% - 27px);
        background: #ffffff;
        box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        z-index: 200;
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        padding: 16px 0 12px;
        justify-items: center;
        row-gap: 30px;

        .sub {
            margin-top: 12px;
            font-size: 12px;
            cursor: pointer;
            padding-left: 20px;
        }
        .icon-youxiang {
            position: relative;
            top: 0px;
            left: 0px;
        }
    }
}
</style>
<style lang="scss">
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 160px;
}
.el-menu--collapse {
    .el-menu-item {
        width: 30px;
        height: 30px !important;
        line-height: 30px !important;
        text-align: center;
        border-right: none;
        margin-left: 17px;
        padding: 0 !important;
        margin-bottom: 10px;

        margin-top: 10px;
        .el-tooltip {
            padding: 0 !important;
        }
        i {
            margin: 0 !important;
            font-style: normal;
        }
    }
    .el-menu-item.is-active {
        background-color: #2370eb;
        color: #fff;
        border-radius: 8px;
    }

    .el-menu-item.is-active:after {
        background-color: transparent;
    }
    .el-submenu {
        height: 30px !important;
        line-height: 30px !important;
        margin-bottom: 10px;
        margin-top: 10px;
        .el-submenu__title {
            height: 30px !important;
            line-height: 30px !important;
        }
        .el-submenu__icon-arrow {
            right: 10px;
        }
        i {
            margin-left: 5px !important;
        }
    }
}
.el-menu-vertical-demo {
    flex: 1;
}
.el-menu {
    border-right: none;
}
.amlm {
    width: 100%;
    height: 100%;
    .is-vertical {
        display: flex;
        flex-direction: column;
    }
    .el-container {
        width: 100%;
        height: 100%;
        display: flex;
        background-color: #f5f7fe;
    }

    .el-header {
        line-height: 48px;
        height: 48px;
        display: flex;
        flex-direction: row;
        position: relative;
        box-shadow: 0px 2px 22px 0px rgba(0, 0, 0, 0.1);
        z-index: 6;
        .img_box {
            width: 130px;
            line-height: 55px;
            padding-left: 10px;
        }
        .nav_box {
            flex: 1;
            font-size: 14px;
            color: #fff;
            > div {
                display: inline-block;
                margin-left: 20px;
                cursor: pointer;
            }
            span {
                cursor: pointer;
                padding: 3px 14px;
            }
            span + span {
                margin-left: 15px;
            }
            .active {
                color: #579fef;
                background-color: #fff;
                border-radius: 25px;
                font-weight: 600;
            }
            // .active:after {
            //   content: "";
            //   position: absolute;
            //   bottom: 0;
            //   left: 0;
            //   width: 100%;
            //   height: 2px;
            //   background-color: #579FEF;
            // }
        }
        .quanju_nav {
            width: 104px;
            height: 24px;
            margin-top: 11px;
            line-height: 24px;
            font-size: 14px;
            color: #fff;
            text-align: center;
            background-color: transparent;
            position: relative;
            border-radius: 17px;
            border: 1px solid #ffffff;
            cursor: pointer;
            user-select: none;
        }
    }
    .side {
        position: relative;
        flex: 1;
        width: 160px;
        box-sizing: border-box;
        padding-left: 8px;
        p {
            height: 40px;
            line-height: 40px;
            color: #333;
            font-size: 13px;
            cursor: pointer;
            i {
                margin: 0 8px 0 15px;
            }
        }
        span {
            display: block;
            width: 100%;
            height: 100%;
        }
        p + p {
            margin-top: 8px;
        }
        span.active {
            color: #2370eb;
            background: #e9f2ff;
            box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.04);
            border-radius: 4px 0px 0px 4px;
            position: relative;
        }
        span.active:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 4px;
            height: 100%;
            background-color: #2370eb;
        }
    }
    .side2 {
        position: relative;
        flex: 1;
        width: 55px;
        box-sizing: border-box;
        padding-left: 8px;
        margin: 0 auto;
        p {
            width: 32px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            color: #333;
            font-size: 13px;
            cursor: pointer;
        }
        span {
            display: block;
            width: 100%;
            height: 100%;
        }
        p + p {
            margin-top: 8px;
        }
        span.active {
            color: #2370eb;
            background: #e9f2ff;
            box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.04);
            border-radius: 4px 0px 0px 4px;
            position: relative;
        }
        span.active:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 4px;
            height: 100%;
            background-color: #2370eb;
        }
    }
    .avatar_box {
        height: 100px;
        width: 100%;
        display: flex;
        position: relative;
        align-items: center;
        .icon-dingdan1 {
            position: absolute;
            top: 28%;
            left: 37px;
            font-size: 12px;
            cursor: pointer;
            color: #fff;
            display: block;
            border-radius: 50%;
            background-color: #f4414e;
            text-align: center;
            line-height: 20px;
        }
        .el-avatar {
            margin-left: 10px;
        }
        p {
            flex: 1;
            span {
                display: block;
                font-size: 12px;
            }
        }
        > span {
            margin-right: 10px;
        }
    }

    .el-aside {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        margin-right: 12px;
        height: 100%;
        position: relative;
        z-index: 6;
    }

    .el-main {
        background-color: #f5f7fe;
        box-sizing: border-box;
        flex: 1;
        margin: 0;
        padding: 12px 16px 0 0;
        position: relative;

        // .breadcrumb {
        //   position: absolute;
        //   font-size: 14px;
        //   font-weight: 400;
        //   color: #666666;
        //   display: flex;
        //   align-items: center;
        //   top: 12px;
        //   .iconfont {
        //     color: #579FEF;
        //     cursor: pointer;
        //     margin-right: 8px;
        //     font-size: 20px;
        //   }
        //   span {
        //     margin-right: 20px;
        //     cursor: pointer;
        //   }
        //   .color {
        //     color: #579FEF;
        //     position: relative;
        //     i {
        //       display: block;
        //       width: 16px;
        //       height: 3px;
        //       background: #579FEF;
        //       border-radius: 1px 1px 0px 0px;
        //       position: absolute;
        //       bottom: -13px;
        //       left: 50%;
        //       margin-left: -8px;
        //     }
        //   }
        // }
    }
}
.el-menu-item {
    min-width: auto !important;
    height: 40px !important;
    line-height: 40px !important;
}
.el-submenu__title {
    height: 40px;
    line-height: 40px;

    // color: #2370eb;
    // background: #e9f2ff;
    // box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.04);
    // border-radius: 4px 0px 0px 4px;
}
.el-submenu__icon-arrow {
    top: 58%;
}
.el-menu-item.is-active {
    color: #2370eb;
    background: #e9f2ff;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.04);
    border-radius: 4px 0px 0px 4px;
    position: relative;
}
.el-menu-item.is-active:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: #2370eb;
}
.toast {
    position: absolute;
    top: 12px;
    right: 20px;
    width: 20px;
    height: 20px;
    font-size: 12px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #182445 !important;
    background-color: #fff;
    text-align: center;
    line-height: 20px;
}
.toast:hover {
    color: #2753ff !important;
}
.journal {
    margin-right: 20px;
    cursor: pointer;
}
.el-avatar {
    width: 40px;
    height: 40px;
    line-height: 40px;
}
</style>
